import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

function flip_bio(person) {
	$('#' + person + '-vl-card').toggleClass('flipped');
	if ($('#' + person + '-vl-card').hasClass('professional-image')) {
		$('#' + person + '-vl-card').removeClass('professional-image').addClass('personality-image');
		$('#' + person + '-bio .front').hide();
		$('#' + person + '-bio .back').show();
	} else {
		$('#' + person + '-vl-card').removeClass('personality-image').addClass('professional-image');
		$('#' + person + '-bio .back').hide();
		$('#' + person + '-bio .front').show();
	}
	$('#' + person + '-bio').toggleClass('flipped');
	if ($('#' + person + '-profile-button').hasClass('personality')) {
		$('#' + person + '-profile-button .profile-button-professional', this).hide();
		$('#' + person + '-profile-button .profile-button-personality', this).show();
		$('#' + person + '-profile-button').removeClass('personality').addClass('professional');
	} else {
		$('#' + person + '-profile-button .profile-button-personality', this).hide();
		$('#' + person + '-profile-button .profile-button-professional', this).show();
		$('#' + person + '-profile-button').removeClass('professional').addClass('personality');
	}
}

$('.vl-card').click(function(){
	var person = $(this).data("person");
	flip_bio(person);
});

$('.menu-open').click(function(){
	$(".top-bar").fadeToggle();
});

$('.menu-close').click(function(){
	$(".top-bar").fadeToggle();
});

$('.profile-button').click(function() {
	var person = $(this).data("person");
	flip_bio(person);
});

$('.vl-bio-text .back').hide();

if ($('#scf-form-contactForm').length) {
	$('#scf-form-contactForm label').each(function(){
		//if statement here
		// use $(this) to reference the current div in the loop
		//you can try something like...

		if ($(this).hasClass('required')) {
			$(this).html($(this).html() + '<span class="required-field-indicator">*</span>');
		}
	});
}

